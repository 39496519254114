@import url('https://fonts.googleapis.com/css2?family=Nunito&family=Short+Stack&display=swap');

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #181b26;
  color:#fff;
  padding-bottom:36px;
}
body.mint {
  background: #181b26 url(/public/chublins-bg.png) bottom center no-repeat fixed;
  background-size:1050px 260px;
  padding-bottom:250px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Short Stack', cursive;
}
ul {
  padding-left:1rem;
}

a, a:link, a:visited {
  color:#0be881;
}
a:focus, a:hover {
  color:#ffdd59;
}
a:active {
  color:#778ca3;
}

nav>div, .Page {
  max-width:760px;
  margin:0 auto;
}

nav {
  padding:2rem 0 0.5rem;
}
nav>div>*{
  display:inline-block;
}
h1 {
  font-size:2rem;
  margin:0 1.5rem 0 0.4rem;
}
.Chublin-logo {
  width:4rem;
  height:4rem;
  vertical-align:middle;
  margin-top:-1.5rem;
}
nav a {
  padding-right:1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.errorText { 
  opacity:0.75; 
  overflow:hidden;  
}

.numberInput, .inlineButton { line-height:1.5; font-size:12pt; }
.numberInput {
  margin-right:0.5rem;
  width:4rem;
}
#mintQuantity {
  width:3rem;
  text-indent:0.5rem;
}
.mintButton {
  margin-right:1rem; 
  font-size:1.25rem;
  padding:4px 8px; 
}
#mintButton { 
  margin-left:1rem;
}
.setLicenseButton { 
  margin-right:1rem;
}

.chublinCard {
  float:left;
  width:300px;
}
.chublinDashboard {
  float:left;
  width:436px;
  margin-left:24px;
}
.chublinTraitsContainer dl {
  overflow:hidden;
}
.chublinTraitsContainer dt, .chublinTraitsContainer dd {
  float:left;
  width:140px;
  padding-bottom:0.25rem;
}
.chublinTraitsContainer dd {
  margin-left:18px;
}
.chublinTraitsContainer dd::first-letter {
  text-transform:uppercase;
}
.chublinOwnerInfo {
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  width:80%;
}
